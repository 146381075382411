import React from 'react'

import SEO from '~components/shared/SEO'
import ResponsiveBlock from '~components/shared/responsive-block'

export default class PrivacyPolicy extends React.Component {
    render() {
        return (
            <>
                <ResponsiveBlock>
                    <div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        PRIVACY NOTICE
                        <br />
                        Last updated August 05, 2020
                        <br />
                        <br />
                        <br />
                        <br />
                        Thank you for choosing to be part of our community at
                        BreachSoftware, LLC (“Company”, “we”, “us”, or “our”).
                        We are committed to protecting your personal information
                        and your right to privacy. If you have any questions or
                        concerns about this privacy notice, or our practices
                        with regards to your personal information, please
                        contact us at contact@Breachsoftware.com.
                        <br />
                        <br />
                        When you use our mobile application, as the case may be
                        (the "App") and more generally, use any of our services
                        (the "Services", which include the App), we appreciate
                        that you are trusting us with your personal information.
                        We take your privacy very seriously. In this privacy
                        notice, we seek to explain to you in the clearest way
                        possible what information we collect, how we use it and
                        what rights you have in relation to it. We hope you take
                        some time to read through it carefully, as it is
                        important. If there are any terms in this privacy notice
                        that you do not agree with, please discontinue use of
                        our Services immediately.
                        <br />
                        <br />
                        This privacy notice applies to all information collected
                        through our Services (which, as described above,
                        includes our App), as well as any related services,
                        sales, marketing or events.
                        <br />
                        <br />
                        Please read this privacy notice carefully as it will
                        help you understand what we do with the information that
                        we collect.
                        <br />
                        <br />
                        <br />
                        <br />
                        TABLE OF CONTENTS
                        <br />
                        <br />
                        1. WHAT INFORMATION DO WE COLLECT?
                        <br />
                        <br />
                        2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                        <br />
                        <br />
                        3. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
                        <br />
                        <br />
                        4. HOW LONG DO WE KEEP YOUR INFORMATION?
                        <br />
                        <br />
                        5. WHAT ARE YOUR PRIVACY RIGHTS?
                        <br />
                        <br />
                        6. CONTROLS FOR DO-NOT-TRACK FEATURES
                        <br />
                        <br />
                        7. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                        <br />
                        <br />
                        8. DO WE MAKE UPDATES TO THIS NOTICE?
                        <br />
                        <br />
                        9. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                        <br />
                        <br />
                        <br />
                        <br />
                        1. WHAT INFORMATION DO WE COLLECT?
                        <br />
                        <br />
                        <br />
                        <br />
                        2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                        <br />
                        <br />
                        In Short: We only share information with your consent,
                        to comply with laws, to provide you with services, to
                        protect your rights, or to fulfill business obligations.
                        <br />
                        <br />
                        We may process or share your data that we hold based on
                        the following legal basis:
                        <br />
                        Consent: We may process your data if you have given us
                        specific consent to use your personal information in a
                        specific purpose.
                        <br />
                        <br />
                        Legitimate Interests: We may process your data when it
                        is reasonably necessary to achieve our legitimate
                        business interests.
                        <br />
                        <br />
                        Performance of a Contract: Where we have entered into a
                        contract with you, we may process your personal
                        information to fulfill the terms of our contract.
                        <br />
                        <br />
                        Legal Obligations: We may disclose your information
                        where we are legally required to do so in order to
                        comply with applicable law, governmental requests, a
                        judicial proceeding, court order, or legal process, such
                        as in response to a court order or a subpoena (including
                        in response to public authorities to meet national
                        security or law enforcement requirements).
                        <br />
                        <br />
                        Vital Interests: We may disclose your information where
                        we believe it is necessary to investigate, prevent, or
                        take action regarding potential violations of our
                        policies, suspected fraud, situations involving
                        potential threats to the safety of any person and
                        illegal activities, or as evidence in litigation in
                        which we are involved.
                        <br />
                        More specifically, we may need to process your data or
                        share your personal information in the following
                        situations:
                        <br />
                        <br />
                        Business Transfers. We may share or transfer your
                        information in connection with, or during negotiations
                        of, any merger, sale of company assets, financing, or
                        acquisition of all or a portion of our business to
                        another company.
                        <br />
                        <br />
                        <br />
                        3. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
                        <br />
                        <br />
                        In Short: We may transfer, store, and process your
                        information in countries other than your own.
                        <br />
                        <br />
                        Our servers are located in. If you are accessing our App
                        from outside, please be aware that your information may
                        be transferred to, stored, and processed by us in our
                        facilities and by those third parties with whom we may
                        share your personal information (see "WILL YOUR
                        INFORMATION BE SHARED WITH ANYONE?" above), in and other
                        countries.
                        <br />
                        <br />
                        If you are a resident in the European Economic Area,
                        then these countries may not necessarily have data
                        protection laws or other similar laws as comprehensive
                        as those in your country. We will however take all
                        necessary measures to protect your personal information
                        in accordance with this privacy notice and applicable
                        law.
                        <br />
                        <br />
                        <br />
                        <br />
                        4. HOW LONG DO WE KEEP YOUR INFORMATION?
                        <br />
                        <br />
                        In Short: We keep your information for as long as
                        necessary to fulfill the purposes outlined in this
                        privacy notice unless otherwise required by law.
                        <br />
                        <br />
                        We will only keep your personal information for as long
                        as it is necessary for the purposes set out in this
                        privacy notice, unless a longer retention period is
                        required or permitted by law (such as tax, accounting or
                        other legal requirements). No purpose in this notice
                        will require us keeping your personal information for
                        longer than __________.
                        <br />
                        <br />
                        When we have no ongoing legitimate business need to
                        process your personal information, we will either delete
                        or anonymize such information, or, if this is not
                        possible (for example, because your personal information
                        has been stored in backup archives), then we will
                        securely store your personal information and isolate it
                        from any further processing until deletion is possible.
                        <br />
                        <br />
                        <br />
                        <br />
                        5. WHAT ARE YOUR PRIVACY RIGHTS?
                        <br />
                        <br />
                        In Short: You may review, change, or terminate your
                        account at any time.
                        <br />
                        <br />
                        If you are resident in the European Economic Area and
                        you believe we are unlawfully processing your personal
                        information, you also have the right to complain to your
                        local data protection supervisory authority. You can
                        find their contact details here:
                        http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
                        <br />
                        <br />
                        If you are resident in Switzerland, the contact details
                        for the data protection authorities are available here:
                        https://www.edoeb.admin.ch/edoeb/en/home.html.
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        6. CONTROLS FOR DO-NOT-TRACK FEATURES
                        <br />
                        <br />
                        Most web browsers and some mobile operating systems and
                        mobile applications include a Do-Not-Track (“DNT”)
                        feature or setting you can activate to signal your
                        privacy preference not to have data about your online
                        browsing activities monitored and collected. At this
                        stage, no uniform technology standard for recognizing
                        and implementing DNT signals has been finalized. As
                        such, we do not currently respond to DNT browser signals
                        or any other mechanism that automatically communicates
                        your choice not to be tracked online. If a standard for
                        online tracking is adopted that we must follow in the
                        future, we will inform you about that practice in a
                        revised version of this privacy notice.
                        <br />
                        <br />
                        <br />
                        <br />
                        7. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                        <br />
                        <br />
                        In Short: Yes, if you are a resident of California, you
                        are granted specific rights regarding access to your
                        personal information.
                        <br />
                        <br />
                        California Civil Code Section 1798.83, also known as the
                        “Shine The Light” law, permits our users who are
                        California residents to request and obtain from us, once
                        a year and free of charge, information about categories
                        of personal information (if any) we disclosed to third
                        parties for direct marketing purposes and the names and
                        addresses of all third parties with which we shared
                        personal information in the immediately preceding
                        calendar year. If you are a California resident and
                        would like to make such a request, please submit your
                        request in writing to us using the contact information
                        provided below.
                        <br />
                        <br />
                        If you are under 18 years of age, reside in California,
                        and have a registered account with the App, you have the
                        right to request removal of unwanted data that you
                        publicly post on the App. To request removal of such
                        data, please contact us using the contact information
                        provided below, and include the email address associated
                        with your account and a statement that you reside in
                        California. We will make sure the data is not publicly
                        displayed on the App, but please be aware that the data
                        may not be completely or comprehensively removed from
                        all our systems (e.g. backups, etc.). <br />
                        <br />
                        <br />
                        <br />
                        8. DO WE MAKE UPDATES TO THIS NOTICE?
                        <br />
                        <br />
                        In Short: Yes, we will update this notice as necessary
                        to stay compliant with relevant laws.
                        <br />
                        <br />
                        We may update this privacy notice from time to time. The
                        updated version will be indicated by an updated
                        “Revised” date and the updated version will be effective
                        as soon as it is accessible. If we make material changes
                        to this privacy notice, we may notify you either by
                        prominently posting a notice of such changes or by
                        directly sending you a notification. We encourage you to
                        review this privacy notice frequently to be informed of
                        how we are protecting your information.
                        <br />
                        <br />
                        <br />
                        <br />
                        9. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                        <br />
                        <br />
                        If you have questions or comments about this notice, you
                        may email us at __________ or by post to:
                        <br />
                        <br />
                        BreachSoftware, LLC <br />
                        8000 Innovation Park Drive
                        <br />
                        Baton Rouge, LA 70820
                        <br />
                        United States
                        <br />
                        <br />
                        <br />
                        HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                        COLLECT FROM YOU?
                        <br />
                        <br />
                        Based on the applicable laws of your country, you may
                        have the right to request access to the personal
                        information we collect from you, change that
                        information, or delete it in some circumstances. To
                        request to review, update, or delete your personal
                        information, please submit a request form by clicking
                        here. We will respond to your request within 30 days.
                        <br />
                        This privacy policy was created using Termly’s Privacy
                        Policy Generator.
                        <br />
                        <br />
                        <br />
                    </div>
                </ResponsiveBlock>
            </>
        )
    }
}
